<!--
 * @Author: hw
 * @Date: 2024-03-22 15:06:51
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-01 11:16:53
 * @FilePath: \dataview-viewer-test\src\views\newSecond\components\overflowPopover\index.vue
-->
<template>
  <el-popover :disabled="disabled || overflowPopoverDisabled" v-bind="$attrs">
    <p>{{ textContent.title }}</p>
    <p>{{ textContent.desc }}</p>
    <template slot="reference">
      <div
        :class="
          lineNum == 1
            ? 'overflow-popover-hidden'
            : 'overflow-popover-line-hidden'
        "
        ref="reference"
        :style="`max-width: ${maxWidth}px;${
          lineNum != 1 ? `-webkit-line-clamp: ${lineNum}` : ''
        }`"
        @mouseenter="checkIsShowPopover"
        @mouseleave="checkIsShowPopover"
      >
        <slot />
      </div>
    </template>
  </el-popover>
</template>

<script>
import { Popover } from 'element-ui'

export default {
  name: 'OverflowPopover',
  components: {
    'el-popover':Popover,
  },
  inheritAttrs: false,
  data() {
    return {
      overflowPopoverDisabled: false,
      parentNodeWidth: 0,
    };
  },
  props: {
    // 是否禁用overflowPopover
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否进行最大宽度检测 （不做最大宽度检测时，overflowPopover相当普通的Popover组件）
    isCheckMaxWidth: {
      type: Boolean,
      default: true,
    },
    // 超出隐藏的宽度
    maxWidth: {
      type: [Number, String],
      default: 180,
    },
    // 针对多行，超出隐藏...的处理
    lineNum: {
      type: Number,
      default: 1,
    },
    textContent: {
      type: Object,
      default: () => ({}),
    }
  },
  mounted() {
  },
  methods: {
    checkIsShowPopover() {
      // 通过获取div节点的getBoundingClientRect属性，拿到div的实际宽度和高度
      const { width, height } = this.$refs.reference.getBoundingClientRect();
      // 多行的情况下，判断高度不用处理宽度
      if (this.lineNum !== 1) {
        // scrollHeight 对应的是实际应该渲染的高度，通过比较二者判断是否显示popover
        const realHeight = this.$refs.reference.scrollHeight;
        this.overflowPopoverDisabled = height >= realHeight;
      } 
      // 只有一行的情况下（无需处理高度）
      else if (this.isCheckMaxWidth) {
        // 开启最大宽度检测时，比较实际的宽度和我们设置传入的maxWidth。
        // 如果没有满足实际小于传入，说明这时候已经出现...隐藏了
        this.overflowPopoverDisabled = width < this.maxWidth;
      }
    },
  },
};
</script>

<style scoped>
.overflow-popover-hidden {
  display: inline-block;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}
.overflow-popover-line-hidden {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  vertical-align: middle;
  word-break: break-all;
  line-height: normal;
  cursor: pointer;
}
</style>

