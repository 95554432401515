/*
 * @Description: 配置
 * @Author: luocheng
 * @Date: 2022-06-20 09:45:43
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-19 14:04:26
 */

export const infoList = [
  {
    type:'1',
    title:'投资完成率',
    enTitle:'Investment completion rate',
    icon: require('@/assets/images/second-screen/invest.png'),
    rate:'',
    rank:'',
    background:'#E8F0FE',
    color:'#3881FF',
    path:'/statistical_data_configuration?pageUUID=page62b90e21b47ef',
    query: {},
    isScreen: false,
  },
  {
    type:'2',
    title:'进度完成率',
    enTitle:'Progress completion rate',
    icon: require('@/assets/images/second-screen/speed.png'),
    rate:'',
    rank:'',
    background:'#FFF1F1',
    color:'#FF6363',
    path:'/child-app-bim/schedule/planSimulation',
    query: {},
    isScreen: false
  },
  {
    type:'3',
    title:'安全评价',
    enTitle:'Safety evaluation ',
    icon: require('@/assets/images/second-screen/security.png'),
    rate:'',
    rank:'',
    background:'#FFF1D6',
    color:'#FFAE22',
    path:'/safety-statistics?pageUUID=page6268f3aad3fbf',
    query: {},
    isScreen: false
  },{
    type:'4',
    title:'质量评价',
    enTitle:'Quality evaluation',
    icon: require('@/assets/images/second-screen/quality.png'),
    rate:'',
    rank:'',
    background:'#DEF4FF',
    color:'#33C5FC',
    path:'/zlxjtj?pageUUID=page628f170c4fe82',
    query: {},
    isScreen: false
  }
]
/**
 * 排名图标
 */
export const rankIconList = [
  require('@/assets/images/second-screen/no.1@2x.png'),
  require('@/assets/images/second-screen/no.2@2x.png'),
  require('@/assets/images/second-screen/no.3@2x.png'),
  require('@/assets/images/second-screen/no.4@2x.png'),
  require('@/assets/images/second-screen/no.5@2x.png'),
]