<template>
	<article class="drawer">
		<section class="grid-tree">
			<el-tree
				:data="treeData"
				@node-click="triggerEvent"
				:default-expand-all="true"
			></el-tree>
		</section>
		<section
			class="show"
			v-if="toggleItem"
		>
			<component
				v-if="isWrite"
				:is="pageData"
			></component>
			<PageParser
				v-else
				:pageUUID="pageData"
			></PageParser>
		</section>
		<footer class="footer">
			<el-row
				type="flex"
				justify="center"
			>
				<el-col
					:span="12"
					class="flex"
				>
				</el-col>
				<el-col
					:span="12"
					class="flex"
				>
					<el-button @click="reset">{{ $t('index.GridDrawer.934494-0') }}</el-button>
					<el-button
						type="primary"
						@click="commit"
						>{{ $t('index.GridDrawer.934494-1') }}</el-button
					>
				</el-col>
			</el-row>
		</footer>
	</article>
</template>

<script>
import PageParser from '@/components/parser/Index';
const componentsList = require.context('@/views/newSecond', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach(fileName => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
//中控台组件
const componentsConsole = require.context('@/console-components', true, /\.+vue$/);
componentsConsole.keys().forEach(fileName => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsConsole(fileName).default;
});

export default {
	name: 'GridDrawer',
	components: {
		...customComponents,
		PageParser
	},
	props: {
		treeData: {
			type: Array,
			required: true,
			default: () => []
		},
		layout: {
			type: Array,
			required: true,
			default: () => []
		},
		drawerIndex: {
			type: Number,
			required: false,
			default: 0
		}
	},
	data() {
		return {
			// 是否是手写页面
			isWrite: false,
			// 页面名称（手写页面） 页面数据（配置页面）
			pageData: null,
			// 切换
			toggleItem: true
		};
	},
	watch: {
		drawerIndex() {
			this.getData();
		}
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.isWrite = this.layout[this.drawerIndex].isWrite;
			this.pageData = this.layout[this.drawerIndex].pageData;
		},
		triggerEvent(row) {
			this.toggleItem = false;
			this.pageData = row.uuid || '';
			// 判断是否为配置页面  （手写页面不含page）
			if (this.pageData && this.pageData.includes('page')) {
				this.isWrite = false;
			} else {
				this.isWrite = true;
			}
			setTimeout(() => {
				this.toggleItem = true;
			}, 10);
		},
		reset() {
			this.pageData = null;
			this.$emit('reset');
		},
		commit() {
			this.$message.success(this.$t('index.GridDrawer.934494-2'));
			this.$emit('commit', this.pageData, this.isWrite);
		}
	}
};
</script>

<style lang="less" scoped>
.drawer {
	width: 100%;
	height: 100%;
	.grid-tree {
		overflow: hidden;
		overflow-y: auto;
		height: 350px;
	}
	.show {
		overflow: auto;
		width: 100%;
		max-height: 400px;
	}
	.footer {
		position: absolute;
		height: 40px;
		bottom: 0;
		right: 0;
		width: 100%;
		z-index: 2;
		.flex {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
