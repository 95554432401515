<template>
  <div class="todo-list-warp" v-loading="loading">
    <div
      class="list-item"
      v-for="(item, index) in getLocalizedData(list, [
        'archi_name',
        'object_name',
        'title',
      ])"
      :key="index"
      @click.stop="handleClick(item)"
    >
      <div class="info-warp">
        <div class="info-text-warp">
          <div class="line-1">
            <p class="number">{{ $t("todoCom.TodoList.785059-NO",[item.flow_approval_number??'-'])}}</p>
           
            <p class="status" :class="filterStatus(item.status).class">
              {{ filterStatus(item.status).label }}
            </p>
          </div>
          <div class="line-2">
            <p class="info-title">  {{ item.t_object_name }}</p>
          </div>
          <div class="line-3">
            <p class="project-name">
              {{ $t("todoCom.TodoList.785059-0") }}{{ item.t_archi_name }}
            </p>
          </div>
          <div class="line-4">
            <div class="info-name">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_4527_6766)">
                  <path d="M10.7234 8.63876C10.4774 8.55165 10.2125 8.50387 9.93682 8.50387C8.63365 8.50387 7.57702 9.56664 7.57702 10.8772C7.57702 11.4237 7.76045 11.9267 8.0688 12.3278H1.01548C0.947726 12.3278 0.882746 12.3009 0.834836 12.2529C0.786925 12.205 0.76001 12.1401 0.76001 12.0723V0.320596C0.76001 0.252841 0.786925 0.187861 0.834836 0.13995C0.882746 0.0920402 0.947726 0.0651245 1.01548 0.0651245L10.4679 0.0651245C10.5357 0.0651245 10.6007 0.0920402 10.6486 0.13995C10.6965 0.187861 10.7234 0.252841 10.7234 0.320596V8.63876Z" fill="#12778D"/>
                  <path d="M11.8914 9.60592C11.6454 9.51881 11.3804 9.47103 11.1048 9.47103C9.80162 9.47103 8.74499 10.5338 8.74499 11.8444C8.74499 12.3908 8.92842 12.8938 9.23677 13.2949H2.18345C2.1157 13.2949 2.05071 13.268 2.0028 13.2201C1.95489 13.1722 1.92798 13.1072 1.92798 13.0395V1.28776C1.92798 1.22 1.95489 1.15502 2.0028 1.10711C2.05071 1.0592 2.1157 1.03229 2.18345 1.03229H11.6359C11.7037 1.03229 11.7686 1.0592 11.8166 1.10711C11.8645 1.15502 11.8914 1.22 11.8914 1.28776V9.60592Z" fill="#03A8CD"/>
                  <path d="M9.15259 11.8911C9.15259 12.4332 9.36791 12.953 9.7512 13.3363C10.1345 13.7196 10.6543 13.9349 11.1964 13.9349C11.7384 13.9349 12.2582 13.7196 12.6415 13.3363C13.0248 12.953 13.2401 12.4332 13.2401 11.8911C13.2401 11.3491 13.0248 10.8292 12.6415 10.446C12.2582 10.0627 11.7384 9.84735 11.1964 9.84735C10.6543 9.84735 10.1345 10.0627 9.7512 10.446C9.36791 10.8292 9.15259 11.3491 9.15259 11.8911Z" fill="#C92121"/>
                  <path d="M11.1964 9.84837V13.9339C11.1751 13.9346 11.1538 13.9349 11.1325 13.9349C10.0391 13.9349 9.15259 13.0198 9.15259 11.8911C9.15259 10.7625 10.0391 9.84735 11.1325 9.84735C11.154 9.84735 11.1752 9.84761 11.1964 9.84837Z" fill="#F67A7A"/>
                  <path d="M6.78203 2.30951H11.3805V3.3314H6.78203V2.30951ZM2.94995 5.1197H11.1251V6.14159H2.94995V5.1197ZM2.94995 7.67442H11.1251V8.69631H2.94995V7.67442Z" fill="#12778D"/>
                  <path d="M3.20542 2.565H5.76014C5.93046 2.565 6.01561 2.65016 6.01561 2.82047V4.35331C6.01561 4.52362 5.93046 4.60878 5.76014 4.60878H3.20542C3.03511 4.60878 2.94995 4.52362 2.94995 4.35331V2.82047C2.94995 2.65016 3.03511 2.565 3.20542 2.565Z" fill="#E7BD56"/>
                  <path d="M4.48278 2.565V4.60878H3.20542C3.13767 4.60878 3.07269 4.58186 3.02478 4.53395C2.97687 4.48604 2.94995 4.42106 2.94995 4.35331V2.82047C2.94995 2.75272 2.97687 2.68774 3.02478 2.63983C3.07269 2.59192 3.13767 2.565 3.20542 2.565H4.48278Z" fill="#FFE4A3"/>
                  <path d="M10.6853 10.9969C10.6853 11.0807 10.7018 11.1638 10.7339 11.2413C10.766 11.3188 10.813 11.3892 10.8723 11.4485C10.9316 11.5079 11.0021 11.5549 11.0795 11.587C11.157 11.6191 11.2401 11.6356 11.324 11.6356C11.4079 11.6356 11.4909 11.6191 11.5684 11.587C11.6459 11.5549 11.7163 11.5079 11.7756 11.4485C11.8349 11.3892 11.882 11.3188 11.9141 11.2413C11.9462 11.1638 11.9627 11.0807 11.9627 10.9969C11.9627 10.913 11.9462 10.8299 11.9141 10.7524C11.882 10.6749 11.8349 10.6045 11.7756 10.5452C11.7163 10.4859 11.6459 10.4388 11.5684 10.4067C11.4909 10.3746 11.4079 10.3581 11.324 10.3581C11.2401 10.3581 11.157 10.3746 11.0795 10.4067C11.0021 10.4388 10.9316 10.4859 10.8723 10.5452C10.813 10.6045 10.766 10.6749 10.7339 10.7524C10.7018 10.8299 10.6853 10.913 10.6853 10.9969Z" fill="white"/>
                  <path d="M10.4315 13.1685C10.4306 13.1473 10.4302 13.126 10.4302 13.1047C10.4302 12.4346 10.8394 11.8912 11.3443 11.8912C11.8491 11.8912 12.2583 12.4346 12.2583 13.1047C12.2583 13.1261 12.2578 13.1473 12.2571 13.1685H10.4315Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4527_6766">
                    <rect width="14" height="14" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div>{{ item.from_user_name }} > {{ $t('todoCom.TodoList.me') }}</div>
            </div>

            <p class="time-status">{{ filterTime(item.endDate).time }}</p>
          </div>
        </div>
      </div>
    </div>
    <Empty
      v-if="list.length === 0"
      :description="$t('todoCom.TodoList.785059-1')"
      :image-size="100"
    ></Empty>
  </div>
</template>
<script>
import { format } from "date-fns";
import { Empty } from "element-ui";
export default {
  components: {
    Empty,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick(item) {
      console.log(item);
      let data = {
        event: "showDetailModal",
        form_uuid: item.form_id,
        flow_id: item.flow_id,
        data_id: item.id,
        module_name: item.module_name,
        object_name: item.t_object_name,
      };
      window.parent.postMessage(data, "*");
      return;
    },
    filterIcon(text) {
      if (!text || text.length === 0) {
        return {
          text: "-",
          class: "",
        };
      }
      const str = text[0];
      const classHash = {
        安: "s-icon",
        质: "q-icon",
        进: "p-icon",
      };
      return {
        text: str,
        class:
          classHash[str] ||
          classHash[
            Object.keys(classHash)[
              Math.floor(Math.random() * Object.keys(classHash).length)
            ]
          ],
      };
    },
    // 列表后面的流程状态
    filterStatus(status) {
      switch (status + "") {
        case "-1": {
          // 退回
          return {
            label: this.$t("todoCom.TodoList.785059-5"),
            class: "w-status",
          };
        }
        case "0": {
          // 发起
          return {
            label: this.$t("todoCom.TodoList.785059-6"),
            class: "d-status",
          };
        }
        case "5": {
          // 完成
          return {
            label: this.$t("todoCom.TodoList.785059-7"),
            class: "s-status",
          };
        }
        case "6": {
          // 拒绝
          return {
            label: this.$t("todoCom.TodoList.785059-16"),
            class: "j-status",
          };
        }
        case "7": {
          // 作废
          return {
            label: this.$t("todoCom.TodoList.785059-17"),
            class: "z-status",
          };
        }
        default: {
          // 审核中
          return {
            label: this.$t("todoCom.TodoList.785059-8"),
            class: "p-status",
          };
        }
      }
    },
    filterTime(time) {
      const targetDate = new Date(time);
      let a = targetDate.getTime()+(24 * 60 * 60 * 1000)
      let bTime = new Date()
      bTime.setTime(a)
      if (isNaN(targetDate.getTime())) {
        return {
          time: "-",
          className: "",
          str: "",
        };
      } else {
        return this.timeFormat(bTime);
      }
    },
    // 时间格式化
    timeFormat(time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const diffTime = this.nowDateObj.getTime() - targetDate.getTime();
      if (diffTime > 24 * 3600 * 1000) {
        return {
          time: this.$t("todoCom.TodoList.785059-9",[Math.floor(diffTime / (24 * 3600 * 1000)),
          ]),
          class: "w-status",
          str: this.$t("todoCom.TodoList.785059-10"),
        };
      }
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      const nd = this.nowDateObj.getDate();
      const td = targetDate.getDate();
      if (ny === ty) {
        if (nm === tm) {
          if (nd === td) {
            return {
              time: this.$t("todoCom.TodoList.785059-11", [
                format(targetDate, "HH:mm"),
              ]),
              class: "s-status",
              str: this.$t("todoCom.TodoList.785059-12"),
            };
          } else if (td - nd === 1) {
            return {
              time: this.$t("todoCom.TodoList.785059-13", [
                format(targetDate, "MM-dd HH:mm"),
              ]),
              class: "p-status",
              str: this.$t("todoCom.TodoList.785059-12"),
            };
          } else {
            return {
              time: this.$t("todoCom.TodoList.785059-14", [
                format(targetDate, "MM-dd HH:mm"),
              ]),
              class: "p-status",
              str: this.$t("todoCom.TodoList.785059-12"),
            };
          }
        } else {
          return {
            time: this.$t("todoCom.TodoList.785059-14", [
              format(targetDate, "MM-dd HH:mm"),
            ]),
            class: "p-status",
            str: this.$t("todoCom.TodoList.785059-12"),
          };
        }
      } else {
        return {
          time: this.$t("todoCom.TodoList.785059-15", [
            format(targetDate, "yyyy-MM-dd HH:mm:ss"),
          ]),
          class: "p-status",
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.todo-list-warp {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .list-item {
    width: 100%;
    display: flex;
    padding: 6px 8px 12px 8px;
    box-sizing: border-box;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    
    margin-bottom: 12px;
    border-radius: 8px;   
    border: 1px solid var(--border-on-brand-weak, rgba(255, 255, 255, 0.06));
    &:hover {
      background: linear-gradient(96deg, rgba(6, 46, 52, 0.2) 1.1%, rgba(14, 63, 79, 0.2) 64.18%, rgba(14, 71, 79, 0.2) 115.46%);
    }
    .info-warp {
      flex-grow: 1;
      width: 100px;
      display: flex;
      .info-icon {
        flex-shrink: 0;
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 6px;
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
      }
      .q-icon {
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.1);
        color: #24aac5;
      }
      .s-icon {
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.1);
        color: #28a2f4;
      }
      .p-icon {
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.1);
        color: #24aac5;
      }
      .other-icon {
        background: #f1f1f1;
      }
      .info-text-warp {
        flex-grow: 1;
        width: 100px;
        .line-1 {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 4px;
          .number {
            color: var(--text-on-brand-placeholder, rgba(255, 255, 255, 0.3));
            /* web/cn/helper/helper-02 */
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.4px;
          }

          .status {
            position: absolute;
            right: 0;
            top: 0;
            flex-shrink: 0;
            box-sizing: border-box;
            line-height: 20px;
            font-size: 12px;
            font-weight: 400;
            white-space: normal;
            display: flex;
            padding: 0px 6px;
            justify-content: center;
            align-items: center;
            border-radius: 0px 8px;
          }
          .w-status {
            background: rgba(254, 104, 104, 0.20);
            color: #FE6868;
          }
          .s-status {
            background: rgba(67, 198, 132, 0.20);
            color: #43C684;
          }
          .j-status{
            background: rgba(164, 172, 189, 0.20);
            color: #A4ACBD;
          }
          .z-status{
            background: rgba(175, 193, 209, 0.20);
            color: #AFC1D1;
          }
          .p-status {
            background: rgba(0, 172, 206, 0.20);
            color: #00ACCE;
          }
          .d-status {
            background: rgba(14, 208, 185, 0.20);
            color: #0ED0B9;
          }
        }
        .line-2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 18px;
          font-size: 12px;
          font-family: "PingFang SC";
          font-weight: 400;
          color: #6d768f;
          line-height: 18px;
          margin-left: 4px;
          margin-bottom: 2px;
          .info-title {
            flex-grow: 1;
            font-size: 14px;
            font-family: "PingFang SC";
            font-weight: 400;
            color: #fff;
            line-height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .line-3{
          margin:0 0 6px 4px;
          .project-name{
            color: var(--text-on-brand-tertiary, rgba(255, 255, 255, 0.50));
            /* web/cn/helper/helper-03 */
            font-family: "PingFang SC";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            letter-spacing: 0.4px;
          }
        }
        .line-4{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left:4px ;
          .info-name{
            display: flex;
            align-items: center;
            div{
              color: var(--text-on-brand-tertiary, rgba(255, 255, 255, 0.50));
              /* web/cn/helper/helper-03 */
              font-family: "PingFang SC";
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 153.846% */
              letter-spacing: 0.4px;
              margin-left: 6px;
            }
          }
          .time-status{
            color: var(--text-on-brand-tertiary, rgba(255, 255, 255, 0.50));

            /* web/cn/helper/helper-03 */
            font-family: "PingFang SC";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            letter-spacing: 0.4px;
          }
        }
      }
    }
    // .time-status{
    //     flex-shrink: 0;
    //     white-space: nowrap;
    //     height: 18px;
    //     font-size: 12px;
    //     font-family: var(--systemFontFamily);
    //     font-weight: 400;
    //     color: #6D788F;
    //     line-height: 18px;
    //     margin-right: 5px;
    // }
  }
}
</style>
