<!--
 * @Author: zx
 * @Date: 2022-06-17 19:37:09
 * @LastEditTime: 2024-05-13 16:52:25
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="news" v-loading="loading">
    <header>
      <div class="left">
        <h4 class="title">{{ $t('news.Index.223775-0') }}</h4>
      </div>
      <div v-show="!showEdit" class="right">
        <i class="iconfont iconxiangyoujiantou2" @click="goNewsRelease"></i>
      </div>
    </header>
    <div class="content" v-if="allNewsList && allNewsList.length">
      <div class="image">
        <el-carousel v-if="imageNewsList.length >= 3" :interval="300000" type="card" style="width: 100%;height: 100%;overflow: hidden;" height="90%" @change="changeCardIndex">
          <el-carousel-item v-for="(item, index) in imageNewsList" :key="'img' + index">
            <div class="image-comtainer" @click="detailClick(item)" :style="{ padding: cardIndex === index ? '0 10px' : '' }">
              <img v-if="showImg(item)" fit="fit" :src="item.page_cover[0].url" style="height: 100%;width: 100%;" :alt="$t('news.Index.223775-1')">
              <div v-else class="img-set">
                <span>{{ $t('news.Index.223775-2') }}</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel v-if="imageNewsList.length === 1" indicator-position="outside" style="width: 100%;height: 100%;overflow: hidden;" height="90%">
          <el-carousel-item v-for="(item, index) in imageNewsList" :key="'img' + index">
            <div class="image-comtainer" @click="detailClick(item)">
              <img v-if="showImg(item)" :src="item.page_cover[0].url" style="height: 100%;width: 100%" :alt="$t('news.Index.223775-1')">
              <div v-else class="img-set">
                <span>{{ $t('news.Index.223775-2') }}</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="image-text">
          {{ allNewsList[cardIndex].caption }}
        </div>
      </div>
      <div class="text">
        <template>
          <div class="text-line" v-for="(item, index) in newsList" :key="'key' + index" @click="detailClick(item)">
            <div class="text-left">
              <div class="num">{{ index+1 }}</div>
              <div class="text">{{ item.caption }}</div>
            </div>
            
            <div class="date-text">{{getData(item)}}</div>
          </div> 
        </template> 
      </div>
    </div>
    <el-empty :image-size="100" image="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/64074f5ed16b07ec0d04e364073ac795.png" v-else :description="$t('todoCom.TodoList.785059-1')"></el-empty>
		<el-dialog
      :title="$t('news.Index.223775-3')"
			:visible.sync="detailVisible"
			modal-append-to-body
			append-to-body
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:destroy-on-close="true"
      :style="fullScreenVisible?'width: 100%;height:100%':''"
			:width="fullScreenVisible?'100vh':'640'"
      :fullscreen="fullScreenVisible"
		>
			<header class="model-header" slot="title">
				<h3>{{ $t('news.Index.223775-4') }}</h3>
				<i v-if="!fullScreenVisible" class="iconfont icondaping-quanping" @click="changeVisible(true)"></i>
				<i v-if="fullScreenVisible" class="iconfont iconbim_Xzhou" @click="changeVisible(false)"></i>
			</header>
			<div class="modify-content" :style="!fullScreenVisible?'max-height: 700px;overflow-y: auto;padding-right: 8px;':'max-height: 100%;width: 640px'">
        <div class="detail-title">{{ detailInfo.caption }}</div>
        <div class="time-and-name">
          <span>{{ getDate(detailInfo) }}</span>
          <span>{{ $t('news.Index.223775-5') }}{{ detailInfo.unit }}</span>
          <span>{{ getPrograma(detailInfo.programa) }}</span>
          <span>{{ getUser(detailInfo) }}</span>
        </div>
        <div class="text" v-html="detailInfo.text"></div>
			</div>
		</el-dialog>
  </div>
</template>
<script>
import { formRequest } from "@/apis/data/form";
import { Carousel, CarouselItem, Empty } from 'element-ui'
import { mapState } from 'vuex';
// import fileUpload from '@/components/fileUpload/newFiles/index'

export default {
  components: {
    'el-carousel': Carousel,
    'el-carousel-item': CarouselItem,
    'el-empty': Empty,
    // fileUpload
  },
  props:{
    // archiID: {
    //   type: [String, Number],
    //   default: ''
    // }
    showEdit: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      allNewsList:[],
      imageNewsList: [],
      newsList: [],
      detailInfo: {},
      detailVisible: false,
      fullScreenVisible: false,
      loading:false,
      cardIndex: 0
    }
  },
  computed: {
    ...mapState(['targetArchi'])
  },
  watch: {
    targetArchi: {
			handler() {
        this.getNewsList()
			},
			deep: true,
			immediate: true
		}
	},
  methods:{
    getNewsList(){
      this.loading = true;
      const url='api/mapi',method='POST';
      const param = {
        object_uuid: 'object628eed17e8ef3',
        view_uuid: 'view661cd73fce5bf',
        __method_name__: 'dataList',
        transcode: 0
      }
      if (this.archiId) {
        param.archi_id = this.archiId
      }
      formRequest(method,url,param).then(res => {
        this.loading=false
        if(res && +res.data.code == 200){
          let data = res.data.data
          this.allNewsList = data
          if (this.allNewsList && this.allNewsList.length >= 3) {
            this.imageNewsList = this.allNewsList.slice(0, 3)
            this.newsList = this.allNewsList
          } else if(this.allNewsList && this.allNewsList.length && this.allNewsList.length < 3) {
            this.imageNewsList =  this.allNewsList.slice(0, 1)
            this.newsList = this.allNewsList
          } else if(!this.allNewsList || this.allNewsList.length === 0) {
            this.imageNewsList = []
            this.newsList = []
          }
        }
      }).catch(()=>this.loading=false)
    },
    getData(data) {
      if (data.publish_date.split('-').length === 3) {
        const mounth = data.publish_date.split('-')[1]
        const day = data.publish_date.split('-')[2].split(' ')[0]
        // console.log(mounth+ '-' + day, 'data')
        return mounth+ '-' + day
      } else {
        return '-'
      }
    },
    showImg(data) {
      // console.log(data)
      if (
        data.page_cover && 
        data.page_cover.length && 
        data.page_cover[0].url && 
        (
          data.page_cover[0].url.indexOf('jpg') !== -1 || 
          data.page_cover[0].url.indexOf('png') !== -1 ||
          data.page_cover[0].url.indexOf('jpeg') !== -1
        )
      ) {
        return true
      }
      return false
    },
    detailClick(data) {
      const url='api/mapi',method='POST';
      const param = {
        object_uuid: 'object628eed17e8ef3',
        view_uuid: 'view62ac6473245e0',
        __method_name__: 'dataInfo',
        data_id: data.id,
        transcode: 0
      }
      this.loading = true;
      formRequest(method,url,param).then(res => {
        this.loading = false;
        this.detailInfo = res.data.data
        this.detailVisible = true
      })
    },
    getUser(data) {
      let str = ''
      if(data && Array.isArray(data.writer) && data.writer.length) {
        data.forEach(element => {
          if (!str) {
            str = element.user_name
          } else {
            str += (',' + element.user_name)
          }
        });
      } else if(data && data.writer && typeof(data.writer) === 'string') {
        str = data.writer
      } else {
        str = '-'
      }
      return str
    },
    getDate(detailInfo) {
      return detailInfo && detailInfo.publish_date ? detailInfo.publish_date.substring(0,10) : '-'
    },
    getGenre(num) {
      const arr = ['通讯', '消息', '散文', '诗歌', '书法', '摄影', '微小说', '其他']
      if (num) {
        return arr[num-1]
      } else {
        return '-'
      }
    },
    getPrograma(num) {
      const arr = ['公司新闻', '企业公告', '基层动态', '科技动态', '党群工作', '重点报道', '质量安全', '基础设施', '水利水电', '其他业务', '文化艺苑', '摄影作品']
      if (num) {
        return arr[num-1]
      } else {
        return '-'
      }
    },
    changeVisible(boo) {
      this.fullScreenVisible = boo
    },
    goNewsRelease() {
			// this.$store.commit('setShowAppCenter', true);
      this.$router.push({
        path: 'press-release',
        query: {
          pageUUID: 'page628f2ce3846b2'
        }
      });
    },
    changeCardIndex(data) {
      this.cardIndex = data
    }
  },
  mounted(){
    this.getNewsList()
  }
}
</script>
<style lang="less" scoped>
.news{
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  background: linear-gradient(180deg, rgba(6, 41, 52, 0.32) 0%, rgba(5, 103, 145, 0.32) 100%);
  backdrop-filter: blur(3px);
  header{
    display: flex;
    justify-content: space-between;
    .left{
      color: #FFF;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.5px;
    }
    .right{
      i{
        font-size: 20px;
      }
    }
  }
  .content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .is-in-stage{
      border-radius: 12px;
    }
    .image {
      width: 100%;
      height: 70%; 
      position: relative;
      .image-comtainer {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 12px;
        img {
          width: 100%;
          height: 100%;
          
        }
        .img-set {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            color: #999;
          }
        }
      }
      .image-text {
        width: 100%;
        height: 22px;
        line-height: 22px;
        color: #fff;
        font-size: 14px;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: rgba(0, 0, 0, 0.2);
        text-align: left;
        padding: 0 10px;
        box-sizing: border-box;
        text-align: center;
        position: absolute;
        bottom: 10%;
        z-index: 10;
      }
    }
    .text {
      flex: 1;
      overflow-y: auto;
      .text-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: flex;
        height: 28px;
        padding:4px;
        align-items: center;
        align-self: stretch;
        box-sizing: border-box;
        margin-bottom: 6px;
        &:hover{
          border-radius: var(--radius-4, 8px);
          background: linear-gradient(96deg, rgba(6, 46, 52, 0.20) 1.1%, rgba(14, 63, 79, 0.20) 64.18%, rgba(14, 71, 79, 0.20) 115.46%);
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(255, 255, 255, 0.50) inset;
        }
        .text-left{
          display: flex;
          align-items: center;
          flex: 1;
          .num{
            display: flex;
            min-width: var(--button-list-field-small, 18px);
            height: 18px;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            border-radius: var(--radius-2, 4px) var(--radius-none, 0px);
            color: #24AAC5;
            border-radius: var(--radius-2, 4px);
            background: rgba(255, 255, 255, 0.10);
          }
        }
        .date-text {
          color: var(--text-on-brand-tertiary, rgba(255, 255, 255, 0.50));
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
          letter-spacing: 0.5px;
          width: 56px;
        }
        .text {
          overflow: hidden;
          color: var(--text-inverse-primary, #FFF);
          text-overflow: ellipsis;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          letter-spacing: 0.3px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          flex: 1 0 0;
          margin-left: 12px;
          
        }
      }
    }
  }
}
  .modify-content {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .detail-title {
      width: 100%;
      height: 32px;
      line-height: 32px;
      text-align: left;
      font-size: 16px;
      font-weight: 550;
    }
    .time-and-name {
      width: 100%;
      height: 20px;
      line-height: 20px;
      display: flex;
      justify-content: flex-start;
      margin: 10px 0 30px 0;
      span {
        height: 20px;
        font-size: 13px;
        color: #999;
        margin-right: 10px;
      }
    }
    .text {
      line-height: normal;
      p {
        line-height: 2;
      }
    }
  }
  
	.model-header {
		display: flex;
		box-sizing: border-box;
		padding-right: 30px;
    justify-content: space-between;
    align-items: center;
    line-height: 16px;
		cursor: pointer;
		.iconfont {
			font-size: 16px;
			box-sizing: border-box;
			padding: 0 5px;
		}
  }
</style>
