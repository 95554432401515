<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-12-28 15:24:35
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-19 15:19:05
 * @FilePath: \dataview-viewer-test\src\views\newSecond\components\safety\index.vue
 * @Description: 南宁机场第二屏  质安统计
-->
<template>
	<div class="safety" v-if='resize' v-loading='loading1 || loading2'>
    <header class="theme">
      <h4>{{ $t('safety.index.730335-0') }}</h4>
      <ul class="legend">
        <li v-for="(item, index) in legendData" :key="index" @click="jumpRouter(item.name)">
          <span class="circle-icon" :style="{background: item.color}"></span>
          <span class="name">{{ $getLocalizedLabel({'zhLabel': item.name, 'enLabel': item.enName}) }}</span>
          <i v-show="!showEdit" class="iconfont iconxiangyoujiantou2"></i>
        </li>
      </ul>
    </header>
    <div id='echarts' style='height:100%;width:100%'></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { dataInterface } from '@/apis/data';
export default {
	components: {},
	props: {
    showEdit: {
      type: Boolean,
      default: false
    }
  },
	data() {
		return {
      legendData: [
      {
        name: '安全发布',
        enName: 'Secure release',
        color: 'rgba(56, 127, 252, 0.70)'
      },
      {
        name: '质量发布',
        enName: 'Quality release',
        color: '#E76B6B'
      }
    ],
			options: {
				xAxis: {
					type: 'category',
					// boundaryGap: false,
					data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          
				},
        color:['rgba(56, 127, 252, 0.70)','#E76B6B'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
				yAxis: {
					type: 'value',
          minInterval:1
				},
				series: [
					{
            name:this.$t('safety.index.730335-1'),
						data: [],
						type: 'line',
						smooth: true,
            showSymbol: false,
						areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(56, 127, 252, 0.70)' // 起始颜色
            }, {
                offset: 1,
                color: 'rgba(56, 127, 252, 0.00)' // 结束颜色
            }])
            },
					},
					{
						name: this.$t('safety.index.730335-2'),
						type: 'line',
						stack: 'Total',
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#E76B6B' // 起始颜色
              }, {
                offset: 1,
                color: 'rgba(231, 107, 107, 0.00)' // 结束颜色
              }])
            },
						smooth: true,
						emphasis: {
							focus: 'series',
						},
						data: [],
					},
				],
			},
      loading1:true,
      loading2:true,
      resize:true,
		};
	},
	watch: {},
	computed: {},
	methods: {
    getData(){
      let xArr = []
      let yArr = []
      let yArr2 = []
      // 安全发布
      dataInterface({},'api/graph/442').then(res=>{
        if(res.data.code === 200){
          res.data.data.forEach(item => {
            xArr.push(item.inspect_date_two)
            yArr.push(item.number)
          });
          this.options.xAxis.data = xArr;
          this.options.series[0].data = yArr
          this.loading1 = false
          this.drawCharts()
        }
      })
      // 质量发布
      dataInterface({},'api/graph/417').then(res=>{
        if(res.data.code === 200){
          res.data.data.forEach(item => {
            yArr2.push(item.number)
          });
          this.options.series[1].data = yArr2
          this.loading2 = false
          this.drawCharts()
        }
      })
    },
    drawCharts(){
      let myChart = echarts.init(document.getElementById('echarts'))
      this.options && myChart.setOption(this.options);
    },
    jumpRouter(name) {
      if (name === this.$t('safety.index.730335-1')) {
        this.$router.push({
					path: '/safty-inspection?pageUUID=page65478155edb75'
				});
      } else {
        this.$router.push({
					path: '/quality-inspection?pageUUID=page654853ffe1839'
				});
      }
    }
  },
	created() {},
	mounted() {
    this.getData()
    // window.addEventListener('resize', function() {
    //     // 处理窗口大小变化的逻辑代码
    //     console.log(1111);
    // });
    window.onresize(()=>{
      this.resize = false;
      this.getData()
      this.resize = true;
    })
  },
};
</script>
<style lang="less" scoped>
.safety {
  background:#fff;
  width: 100%;
  height: 100%;
  padding: 6px 20px 20px 20px;
  box-sizing:border-box;
  border-radius: 8px;
  .theme {
    display: flex;
    justify-content: space-between;
    width: 100%;
    h4 {
      font-size:16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.5px;
      white-space: nowrap;
      font-size: 16px;
      font-family: var(--systemFontFamily);
      color: #2a2f3d;
      letter-spacing: 1px;
    }
    .legend {
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        .circle-icon {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
        .name {
          font-family: 'Source Han Sans CN';
          font-size: '12px';
          letter-spacing: '0.6px';
        }
        i {
          font-size: 18px;
          color: gray;
        }
      }
    }
  }
}
</style>
