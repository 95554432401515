<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-12-28 15:23:39
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-21 16:20:45
 * @FilePath: \dataview-viewer-test\src\views\newSecond\components\people\index.vue
 * @Description: 南宁机场第二屏需求 考勤统计
-->
<template>
  <div class="people-second" v-loading='loading'>
    <header class="theme">
      <h4>{{ $t('people.index.282976-0') }}</h4>
      <i v-show="!showEdit" class="iconfont iconxiangyoujiantou2" @click="jumpRouter"></i>
    </header>
    <div class='list-box' @click="jumpRouter">
      <div v-for="(item,index) in listData" :key="index" class='list' :style="index%2===0?'background:#F4F6FA':''">
        <div class='list-left'>
          <div v-if='index<5' class='list-img'>
            <img :src="getImage(index)" alt="">
          </div>
          <div v-else class='list-index'>{{index+1}}</div>
          <div class='list-name'> {{item.name}}</div>
        </div>
        <div class='list-right'>
          {{item.number}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { dataInterface } from '@/apis/data';
export default {
  components: {},
  props: {
    showEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      listData:[]
    };
  },
  watch: {},
  computed: {},
  methods: {
    getData(){
      dataInterface({},'api/graph/420').then(res=>{
        if(res.data.code === 200){
          this.listData = res.data.data
          this.loading = false;
        }
      })
    },
    jumpRouter() {
      this.$router.push({
        path: '/attendance-statistics?pageUUID=page656c235ea2637'
      });
    },
    getImage(index){
      switch (index) {
        case 0:
          return 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/591ed20c58a015063acfc3b1c4408752.png'
        case 1:
          return 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/5cb11ac51b74168754a6028f2d99c504.png'
        case 2:
          return 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/0fc708e67ea5d4faf14afcb1b3a50e3e.png'
        case 3:
          return 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/4b066369cfd938d2d54803212052f7ea.png'
        case 4:
          return 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/b353a9eeadee67d94ca5cdf5fac28de9.png'
      }
    }
  },
  created() {},
  mounted() {
    this.getData()
  }
};
</script>
<style lang="less" scoped>
.people-second{
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 6px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .theme {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-size:16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.5px;
      white-space: nowrap;
      font-size: 16px;
      font-family: var(--systemFontFamily);
      color: #2a2f3d;
      letter-spacing: 1px;
    }
    i {
      font-size: 18px;
      color: gray;
      cursor: pointer;
    }
  }
  .list-box{
    overflow:auto;
    flex:1;
    margin-top:16px;
    .list{
      display: flex;
      height: 40px;
      padding: 0px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 6px;
      color: var(--sys-text-text-primary, #161C1F);
      font-family: var(--systemFontFamily);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.5px;
      .list-left{
        display:flex;
        align-items:center;
        .list-img{
          width: 15px;
          height: 20px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .list-index{
          width: 15px;
          height: 20px;
        }
        .list-name{
          margin-left:16px;
        }
      }
    }

  }
}
</style>