<!--
 * @Author: zyf
 * @Date: 2022-07-13 17:09:11
 * @LastEditTime: 2024-04-13 16:16:51
 * @Description: 
 * @LastEditors: hw 315249041@qq.com
-->
<template>
    <div class="dynamic-list-warp">
        <div class="list-box" :style="rankList && rankList.length ? 'width: 80%;' : 'width: 100%;'" @scroll="scrollEvent">
          <div class="list-item" v-for="(item,index) in dataList" :key="index" >
            <div class="item-box" >
              <div class="avatar-box" :class="!!item.defaultIcon ? 'defaultIcon' : ''">
                <img
                  :src="item.avatar"
                  class="head-icon"
                />
              </div>
              <div class="item-user-name">
                {{ item.user_name }}
              </div>
            </div>
            <div class="item-content">
          <div class="item-content-left" ref="contentLeft">
            {{ item.type_name }}{{ $t('plalformDynamics.dynamicList.018760-0') }}{{ getObjectName(item.object_name) }}】
          </div>
          <div class="item-content-right">
            {{ item.created_at | switchTime }}
          </div>
        </div>
          </div>
          <Empty v-if="list.length === 0" :description="$t('todoCom.TodoList.785059-1')"  :image-size="100"></Empty>
        </div>
        <div v-if="rankList && rankList.length" class="rank-box">
          <div v-for="(item,index) in rankList"  :key="item.id" class="icon-box">
            <img :src="rankIconList[index]" class="rank-icon">
            <el-tooltip class="item" :content="item.user_name" placement="bottom">
              <span class="avatar-box" :class="!!item.defaultIcon ? 'defaultIcon' : ''">
                <img :src="item.avatar" class="head-icon">
              </span>
            </el-tooltip>
            
          </div>
        </div>
    </div>
</template>
<script>
import { Empty,Tooltip } from 'element-ui'
import { rankIconList } from './config'
export default {
    components: {
        Empty,
        'el-tooltip': Tooltip,
    },
    props: {
        list: {
          type: Array,
          default:()=>[]
        },
        rankData:{
          type: Array,
          default:()=>[]
        },
        isUpdate:{
          type:Boolean,
          default:true
        }
    }, 
    data() {
        return {
          dataList:this.list,
          rankList:this.rankData,
          rankIconList:rankIconList,
        }
  },
  filters: {
    switchTime(time) {
      const m = time.slice(5, 7);
      const d = time.slice(8, 11);
      const h = time.slice(11, 13);
      const mm = time.slice(14, 16);
      return `${m}/${d} ${h}:${mm}`;
    }
  },
    computed:{
      getObjectName () {
        return function (val) { 
          if (val.length <= 4) {
            return val
          } else { 
            return val.slice(0,4) + '...'
          }
        }
      }
    },
    methods: {
      scrollEvent(e){
        if((e.target.offsetHeight + e.target.scrollTop) - e.target.scrollHeight === 0){
          if(this.isUpdate){
            this.$emit('refresh')
          }else{
            this.$message.warning(this.$t('plalformDynamics.dynamicList.018760-1'))
          }
        }
      }
    },
}
</script>

<style lang="less">
  .dynamic-list-warp{
    ::-webkit-scrollbar{
      width:  0 !important;
    }
    ::-webkit-scrollbar{
      width: 0 !important;
      height: 0;
    }
  }
</style>

<style lang="less" scoped>
  .dynamic-list-warp{
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .list-box{
      height: 100%;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;
      
      .list-item{
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 8px 12px;
        background: #f5f6f7;
        margin-bottom: 12px;
        border-radius: 6px;
        box-sizing: border-box;
        overflow: hidden;

        .item-box{
          display: flex;
          align-items: center;
          max-width: 240px;
          .item-user-name{
            font-size: 14px;
            font-family: var(--systemFontFamily);
            font-weight: 400;
            color: #2a2f3d;
            line-height: 22px;
            padding-left: 8px;
          }
        }
        .item-content{
          flex: 1;
          display: flex;
          overflow: hidden;
          box-sizing: border-box;
          font-size: 14px;
          font-family: var(--systemFontFamily);
          font-weight: 400;
          color: #2a2f3d;
          line-height: 22px;
          padding-left: 8px;  
          flex-wrap: wrap;
          .item-content-left{
            flex: 1;
            min-width: fit-content;
            white-space: nowrap;
          }
          .item-content-right{
            padding-left: 12px;
            font-size: 13px;
            font-family: var(--systemFontFamily);
            font-weight: 400;
            color: #787E8F;
            display: inline-block;
            flex-grow: 1;
            text-align: right;
          }
        }
      }
    }
    
    .rank-box{
      box-sizing: border-box;
      overflow: hidden;
      .icon-box{
         display: flex;
         align-items: center;
         padding-bottom: 16px;
        .rank-icon{
          display: block;
          width: 20px;
          height: 20px;
          padding-right: 12px;
        }
      }
      
    }
    .avatar-box{
      width: 20px;
      height: 20px;
      display: inline-block;
      overflow: hidden;
      vertical-align:middle;
      border-radius: 4px;
      .head-icon{
        width: 20px;
        height: 20px;
        display: block;
        line-height: 20px;
      }
    }
    .defaultIcon{
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.1);
    }
  }
</style>
